// assets\stylesheets\pages\_sell-with-us.scss


//--------------------------------------------------------------------------------------
//  Intro
//--------------------------------------------------------------------------------------

.sell-with-us-intro {
  margin-bottom: $spacing-y * 3;

  @include tablet() {
    .img-box figure {
        flex-basis: 60%;
    }
  }
}

//--------------------------------------------------------------------------------------
//  Section instant access
//--------------------------------------------------------------------------------------

.sell-with-us-instant-acccess {
  margin-bottom: $spacing-y * 3;

  @include tablet() {
    .img-box figure {
        flex-basis: 60%;
    }
  }

  .btn {
    max-width: 220px;
    text-align: center;
  }
}

//--------------------------------------------------------------------------------------
//  Section features (marketing, knowledge, experience)
//--------------------------------------------------------------------------------------

.sell-with-us-features {
  background: $color-primary;
  color: $white;
  @include paddingY($spacing-y * 3);
  @include paddingX($spacing-x);

  .section-title {
    text-align: center;
    margin-bottom: $spacing-y * 3;
    font-size: 1.4rem;
    color: $white;
  }

  @include tablet() {
    .section-title {
      font-size: 2rem;
      margin-bottom: $spacing-y * 1.5;
    }
  }
}

//--------------------------------------------------------------------------------------
//  Section Helping you
//--------------------------------------------------------------------------------------

.sell-with-us-helping {
  @include marginY($spacing-y);

  @include tablet() {
    @include marginY($spacing-y * 3);

    .img-box figure {
      flex-basis: 80%;
      padding-right: $spacing-x * 4;
    }
  }
}

.international-visibility {
  @extend .sales-marketoid;
  background-color: $color-bg-primary;

  p {
    max-width: 500px;
    margin: 0 auto $spacing-y * 2;
  }
}
