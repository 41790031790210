// assets\stylesheets\pages\_contact.scss

.contact {
  @include container;
  margin-top: $spacing-y;
  margin-bottom: $spacing-y * 4;

  .section-title {
    color: $color-accent;
    margin-bottom: $spacing-y * 1.5;
  }

  &-infos {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include marginY($spacing-y * 2);

    a {
      color: $color-primary;
      display: flex;

      &:not(:last-of-type) {
        margin-bottom: $spacing-y * 1.2;
      }

      span {
        margin-left: $spacing-x * 2;
      }
    }
  }


  @include tablet() {

    &__wrapper {
      display: flex;

      form {
        flex: 0 55%;
      }
    }

    .btn {
      margin-left: 0;
      margin-right: auto;
    }

    label {
      font-size: $font-size-md;
    }

    &-infos {
      margin-top: $spacing-y * 1.8;
      margin-bottom: auto;
      margin-left: $spacing-x * 8;
    }
  }
}
