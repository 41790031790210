
.media-object {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-md * 4;
  flex-wrap: wrap;

  @include big-mobile {
    flex-direction: column;
  }

  & > * {
    width: calc(100% / 2);

    @include big-mobile {
      width: 100%;
    }

  }

  h3 {
    @extend .section-title;
  }

  ._image {
    @include big-mobile {
      margin-bottom: $space-md;
    }
  }

  ._text {
    // color: $white;
    padding: $space-md * 3;

    @include big-mobile {
      padding: 0;
    }

    & > * {
      margin-bottom: $space-md;
    }

    p {
      line-height: 1.5;
    }
  }

}

// media object case in accent color banner
.lake-cta-banner {
  background: $color-primary;
  padding: $space-md * 4 0;

  ul {
    @include container();
  }

  .media-object {

    ._text {
        color: $white;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(odd) {
      flex-direction: row;

      @include big-mobile {
        flex-direction: column;
      }

      ._text {
        padding-right: 0;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @include big-mobile {
        flex-direction: column;
      }


      ._text {
        padding-left: 0;
      }
    }
  }
}
