.map-properties-sold {
  background: $color-primary;
  color: $white;
  text-align: center;
  padding: $space-lg * 3 0;

  & > * {
    margin-bottom: $space-md;
  }

  h2 {
    @include section-title;
    display: block;
    max-width: 750px;
    margin: 0 auto 2rem;
    color: $white;
  }

  p {
    @include section-body;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
}
