form {
  display: flex;
  flex-direction: column;

  label {
    display: block;
    font-size: $font-size-default;
    line-height: 1.9;
  }

  .input-group {
    &:not(:last-child) {
      margin-bottom: $spacing-y * .8;
    }
  }

  .btn {
    @include marginY($spacing-y * .5);
    margin-left: auto;
    cursor: pointer;
  }

  .field {
    width: 100%;
    padding: .875em .5em;
    border: 1px solid $color-text-muted;
    background: transparent;
  }
}

.form-modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  top: 0; left: 0;
  z-index: 1001;


  &.active {
    display: block;
  }

  ._btn-close {
    font-size: 40px;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    color: #000;
    z-index: 1002;
  }

  form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    max-width: 500px;
    max-height: 98vh;
    background: #fff;
    border: 1px solid #ededed;
    padding: 1rem;
    overflow: auto;

    h2 {
      font-family: $font-primary-medium, sans-serif;
      margin-bottom: $spacing-y;
      font-size: 1.25rem;

      @include tablet() {
        font-size: 1.75rem;
      }
    }
  }
}
