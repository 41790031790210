// assets\stylesheets\pages\_about.scss

//--------------------------------------------------------------------------------------
//  Section living
//--------------------------------------------------------------------------------------

.about-living {
  margin-bottom: $spacing-y * 1.5;

  @include tablet() {
    margin-bottom: $spacing-y * 3.5;
    .img-box figure {
      flex-basis: 50%;
    }
  }
}

//--------------------------------------------------------------------------------------
//  Section features
//--------------------------------------------------------------------------------------

.about-features {
  @include paddingY($spacing-y * 1.5);
  background: $color-primary;
  color: $white;

  .section-title {
    text-align: center;
    margin-bottom: $spacing-y * 1.5;
    font-size: 1.4rem;
    color: $white;
  }

  @include tablet() {
    .section-title {
      font-size: 2rem;
    }
  }

  .subtitle {
    color: $color-accent;
  }
}

//--------------------------------------------------------------------------------------
//  Section founder
//--------------------------------------------------------------------------------------

.about-founder {

  .section-title {
    color: $color-accent;
    margin-bottom: $spacing-y;
  }

  & > div {
    @include paddingX($content-gutter * 1.2);
    @include marginY($spacing-y * 1.8);
  }

  figure {
    max-width: 400px;
  }

  p {
    letter-spacing: 1px;
    @include tablet() {
      font-size: 1.125rem;
    }
  }

  p:not(:last-child) {
    margin-bottom: $spacing-y;
  }

  @media (min-width: 401px) {
    figure {
      max-width: 100%;
    }
  }

  @include tablet() {
    display: flex;
    justify-content: space-between;
    @include container;
    @include marginY($spacing-y * 2);

    div {
      flex: 0 55%;
    }

    figure {
      @include marginY($spacing-y * 1.8);
      flex: 0 40%
    }
  }
}
