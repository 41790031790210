//layout/_nav.scss

$li-spacing-tablet: $spacing-x * 2;

.site-header {
  background: $color-primary;
  border-bottom: 4px solid $color-accent;

  .container {
    @include paddingY($spacing-y, $spacing-y * .875);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  nav .btn {
    text-align: center;
    padding: .5em 1.5em;
  }

  &__logo {
    width: 50%;

    @media (min-width: $tablet) {
      width: 150px;
    }

    @media (min-width: $big-tablet) {
      width: auto;
    }
  }
}

.main-nav {
  ul {
    position: fixed;
    transform: translateX(4000px) skewX(-70deg);
    transition: .4s ease-in-out;
    width: 100%;
    height: 100%;
    left: 0;
    top: 56px;
    z-index: 100;
    background: $color-primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    li {
      font-size: $font-size-md-lg;
      margin: 0 auto;
      padding-bottom: $space-lg * 1.2;

      &:nth-of-type(2) {
        order: 5;

        @include tablet() {
          display: none;
        }
      }

      @include tablet() {
        &:nth-of-type(5) {
          display: none;
        }
      }
    }

    &.is-opened {
      transform: translateX(0);
      transition: .4s ease-in-out;
    }
  }

  @include tablet() {
    ul {
      position: static;
      transform: translateX(0);
      transition: none;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;

      li {
        padding-bottom: 0;
        font-size: $font-size-default;
        padding-left: 1rem;
        padding-right: 1rem;

        a {
          white-space: nowrap;
        }
      }
    }

    .btn-nav {
      display: none;
    }
  }
}


.secondary-nav {
  padding-right: 3rem;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li:first-of-type {
    display: none;
    padding-right: $spacing-x * 2;

    @include tablet() {
      display: inline-block;
    }
  }

  @include tablet() {
    padding-right: 0;
  }
}

.nav-link {
  a {
    color: $white;
    letter-spacing: .5px;
    @include underline($white)
  }
}
