.gallery-listing {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  margin-bottom: $space-md;

  @include big-mobile {
    display: block;
  }

  li {
    &:nth-child(1),
    &:nth-child(2) {
      width: calc((100% / 2) - #{$space-md});
      @include marginX($space-sm);

      // disable to use different layout
      // @include big-mobile {
      //   width: 100%;
      //   @include marginX(0);
      // }
    }

    // disable to use different layout
    // @include big-tablet {
    //   width: 100%;
    // }
  }
}

#photo-gallery {
  margin-bottom: $space-md;
  .slider-control-btn--prev {
    left: .5rem;
  }
  .slider-control-btn--next {
    right: .5rem;
  }

  @include big-mobile {
    .slider-control-btn--prev {
      left: 0;
    }
    .slider-control-btn--next {
      right: 0;
    }
  }
}

.gallery-listing--roll {
  // display: flex;

  li {
    // width: calc((100% - #{$space-md}));
    width: calc((100% - .95rem));
    @include marginX(auto);
  }
}

.gallery-control {
  position: relative;


  .btn {
    width: 50px;
    height: 50px;
    background: $color-accent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);



    &#gallery_prev {
      left: $space-sm;
    }

    &#gallery_next {
      right: $space-sm;
    }

  }
}

// Action control
.action-control {
  @include contentWidth(padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-md;

  @include big-tablet {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > * {
      margin-bottom: $space-md;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Gallery mobile layout
@include big-mobile {
  .gallery-listing {
    display: flex;
    flex-wrap: wrap;

    // show more related
    max-height: 350px;
    overflow: hidden;
    // border: 1px solid red;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 25%;
      background-image: linear-gradient(to top, #ffffff, transparent, transparent);
      background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0)), color-stop(0.9, rgb(255, 255, 255)), to(white));
    }

    &.is-opened {
      max-height: none;

      &::after {
        display: none;
      }
    }
    // end of show more related

    li {
      margin: 0;
      margin-bottom: $space-sm * 1.5;

      &:nth-child(1) {
        width: 100%;
        @include marginX(0);
      }
      &:nth-child(2) {
        width: calc((100% / 4) - #{$space-sm});
        @include marginX(0);
      }

      width: calc((100% / 4) - #{$space-sm});
    }
  }
}


.bg-as-img {
  position: relative;
  padding-top: 60%;
  width: 100%;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  img {
    display: none;
  }
}

// Lightbox
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($black, .9);
  z-index: 1000;
  display: block;
  width: 100%;
  height: 100%;
}

.lightbox__content {
  height: 100%;
  width: 100%;

  & > div,
  & > div > div {
    height: 100%;
    width: 100%;
  }

  li {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: auto;
    height: auto;
    position: relative;
    display: block;
    object-fit: contain;
    max-height: 90%;
    max-width: 90%;
  }
}

.lightbox__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #333;
  }
}

.lightbox .slider-control-btn.slider-control-btn--prev {
  left: 0;
}

.lightbox .slider-control-btn.slider-control-btn--next {
  right: 0;
}


.slider {

}

.slider-wrapper {
  position: relative;
  margin-bottom: 0;
}

.slider-control-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: $color-accent;
  width: 45px;
  height: 35px;
  padding: $space-sm / 2 $space-sm;
  cursor: pointer;
  z-index: 10;

  &:after {
    content: '';
    background-image: url('/images/icons/arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    display: block;
    width: 100%;
    height: 100%;
    filter: brightness(10);
  }

  &.slider-control-btn--prev {
    left: 0;
    &:after {
      transform: rotate(180deg);
    }

    @include big-mobile {
      left: -$space-md;
    }
  }

  &.slider-control-btn--next {
    right: 0;

    @include big-mobile {
      right: -$space-md;
    }
  }
}

