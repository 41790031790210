.opt-in {
  background-color: $color-bg-secondary;
  &.-alt { background-color: $color-bg-primary; }
  margin-bottom: 0;
  padding-top: $spacing-y * 3;
  padding-bottom: $spacing-y * 2;

  @include tablet() {
    .img-box figure {
        flex-basis: 60%;
    }
  }

  .btn {
    max-width: 250px;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

section.contrast {
  background: $color-primary;
  color: $white;

  ._content {
    @include container;
    @include paddingY($spacing-y * 1.2, $spacing-y * 3);
    text-align: center;

    h2 {
      @include section-title;
      display: block;
      max-width: 750px;
      margin: 0 auto 2rem;
      color: $white;
    }

    p {
      @include section-body;
      margin-bottom: 2rem;
      line-height: 1.5;
    }
  }

  @include tablet() {
    ._content {
      @include paddingY($spacing-y * 4, $spacing-y * 6);
    }
  }
}

.testimonials {
  @include paddingY($spacing-y * 2);
  background: $color-bg-secondary;
  position: relative;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .siema {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .testimonial {
    min-width: 100%;
    @include paddingX($spacing-x * 3);

    &:not(:last-child) {
      margin-bottom: $spacing-y * 3;
      margin-right: $spacing-x;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      transform: translateX(-20px);
      margin-bottom: $spacing-y * .5;
    }

    p {
      line-height: 1.5;
    }

    p:first-of-type {
      @extend .text-italic;
      margin-bottom: $spacing-y;
      width: 90%;
    }
  }

  .slider-controls {
    margin-top: 1rem;
    //margin: 0 auto;
    text-align: center;
  }

  .slider-bullet {
    margin-right: 1rem;
    height: .75rem;
    width: .75rem;
    background-color: $color-primary;
    border-radius: 50%;
    border: 1px solid $color-primary;
    display: inline-block;

    &.selected {
      background: $color-accent;
      border-color: $color-accent;
    }
  }

  .slider-btn {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    border: none;
    background: #0E2B58;
    width: 45px;
    height: 35px;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    z-index: 10;

    &::after {
      content: '';
      background-image: url(/images/icons/arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 40%;
      display: block;
      width: 100%;
      height: 100%;
      filter: brightness(10);
    }

    &.prev {
      left: 0;
      &::after {
        transform: rotate(180deg);
      }
    }

    &.next {
      right: 0;
    }
  }
}

// Sales marketoid component that's shared between pages

.sales-marketoid {
  background-color: $color-bg-secondary;

  & > div {
    @include container;
    @include paddingY($spacing-y * 3);
    text-align: center;
  }

  h2 {
    @include section-title;
    color: $color-accent;
    margin-bottom: $spacing-y * 1.5;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-y;

    @include tablet() {
      font-size: 2rem;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: $spacing-y;

    @media (min-width: $big-tablet) {
      justify-content: space-between;
    }

    li {
      max-width: 300px;
      margin: 1rem;

      @media (min-width: $big-tablet) {
        width: calc(33% - 2rem);
      }
    }
  }
}

.sales-marketoid-notice {
  & > div {
    @include container;
    @include paddingY($spacing-y);
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    font-size: 12px;
  }
}
