// assets\stylesheets\components\_boxes.scss

$col-spacing: $spacing-x * 3;

//--------------------------------------------------------------------------------------
//  img-box
//--------------------------------------------------------------------------------------
// 2 columns layout for resolutions $tablet and greater, 1 column default (mobile first)
//    col 1: figure,
//    col 2: h2, p
//           h2 can be offset vertically by adding the class "offset-top"
// add "reverse" class to switch column order

.img-box {
  @include container;
  display: flex;
  flex-direction: column;


  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    margin-bottom: $spacing-y * 1.5;
  }

  img {
    width: 100%;
  }

  p {
    margin-bottom: $spacing-y;
    letter-spacing: 1px;
  }

  figure {
    display: none;
  }

  @include tablet() {
    flex-direction: row;
    justify-content: space-between;

    div {
      flex-basis: 65%;
      padding-right: $col-spacing;
    }

    p {
      font-size: 1.125rem;
    }

    figure {
      display: block;
    }

    &.reverse {
      flex-direction: row-reverse;

      figure, ._img {
        padding-right: $col-spacing;
      }

      div {
        @include paddingX(0);
      }
    }
  }
}

//--------------------------------------------------------------------------------------
//  Features (used in page sell-with-us, about)
//--------------------------------------------------------------------------------------
// 3 columns layout
// content: img (can be omitted), heading, paragraph in this order
// to keep alignment, make sure images have the same height

.features {
  $col-space-md: $spacing-x * 3;
  $col-space-lg: $spacing-x * 4;

  @include container;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  // if we have one
  .section-title {
    @include marginX(auto);
  }

  & > * {
    margin-bottom: $spacing-y * 2;

    .subtitle {
      font-weight: $font-weight-bold;
      font-size: $font-size-default;
      @include marginY($spacing-y);
    }

    figure {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 90px;
    }

    p {
      @include paddingX($spacing-x * 3);
      line-height: 1.7;
      letter-spacing: 1px;
      font-size: $font-size-sm;
    }
  }

  @include mobile() {
    & > * p {
      @include paddingX(20%);
    }
  }

  @include tablet() {
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
    & > * {
      flex-basis: calc((100% / 3) - #{$col-space-md});
    }

    & > div p {
      @include paddingX(0);
    }
  }

  @include desktop() {
    & > * {
      flex-basis: calc((100% / 3) - #{$col-space-lg});
    }
    .subtitle {
      font-size: 1.5rem;
    }

    & > div p {
      font-size: $font-size-default;
    }
  }
}
