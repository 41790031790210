// _home.scss

//-------------------------------------------------------------------------
// Section Home - specialized
//-------------------------------------------------------------------------

.home-hero {
  background: url(/images/home/hero-mobile.jpg) top center no-repeat;
  background-size: cover;
  padding-top: 14rem;
  padding-bottom: 5vw;
  text-align: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
  }

  .container {
    position: relative;
    z-index: 1;
    @include paddingY(3rem);
  }

  h1 {
    text-transform: uppercase;
    font-family: $font-primary-light;
    letter-spacing: 1.9px;
    color: $white;
    font-size: 0.8rem;
    line-height: 1.8;
    margin-bottom: $spacing-y * 1.5;

    span {
      display: block;
      font-family: $font-primary-medium;
      font-size: 1.5em;
      line-height: 1.5;
    }

    small {
      font-size: $font-size-sm;
    }

    ._desktop {
      display: none;
    }

    ._mobile {
      display: block;
    }

    @include tablet() {
      ._desktop {
        display: block;
      }

      ._mobile {
        display: none;
      }
    }
  }

  @include tablet() {
    background: url(/images/home/hero.jpg) top center no-repeat;
    background-size: cover;
    padding-top: 30vw;

    &::after {
      content: none;
    }

    h1 {
      line-height: 2.5;
      margin-bottom: 3rem;
      font-size: 1.2em;
      letter-spacing: 3px;

      small {
        font-size: 1em;
      }

      span {
        font-size: 1.45em;
        line-height: 1;
      }
    }
  }

  @include desktop() {
    h1 {
      span {
        font-size: 1.5em;
      }
    }
  }
}
