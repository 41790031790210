%heading {
  font-family: $font-primary;
}

h1,
h2,
h3,
h4 {
  @extend %heading;
}

h1 {
  font-size: $font-size-lg;
}

h2 {
  font-size: $font-size-lg;
}

.section-title {
  @include section-title;
}

.section-body {
  @include section-body;
}

.text-bold {
  font-weight: $font-weight-bold;
}

.text-italic {
  font-style: italic;
}

.color-accent {
  color: $color-accent;
}

