// assets\stylesheets\abstracts\_mixins.scss


@mixin paddingX($left, $right: 0) {
  padding-left: $left;
  @if ($right == 0) {
    padding-right: $left;
  } @else {
    padding-right: $right;
  }
}

@mixin paddingY($top, $bottom: 0) {
  padding-top: $top;
  @if ($bottom == 0) {
    padding-bottom: $top;
  } @else {
    padding-bottom: $bottom;
  }
}

@mixin marginX($left, $right: 0) {
  margin-left: $left;
  @if ($right == 0) {
    margin-right: $left;
  } @else {
    margin-right: $right;
  }
}

@mixin marginY($top, $bottom: 0) {
  margin-top: $top;
  @if ($bottom == 0) {
    margin-bottom: $top;
  } @else {
    margin-bottom: $bottom;
  }
}


@mixin centered-horizontal() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centered-vertical($offset: 0%) {
  top: 50%;
  transform: translateY((-50% + $offset));
}

@mixin centered() {
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}


@mixin container() {
  margin: 0 auto;
  @include paddingX($content-gutter * 1.2);

  @include mobile() {
    @include paddingX($content-gutter * 1.5);
  }

  @include tablet() {
    @include paddingX($content-gutter * 2);
  }

  @media screen and (min-width: $desktop) {
    max-width: $desktop;
    @include paddingX($content-gutter);
  }

}

//--------------------------------------------------------------------------------------
//  media queries
//--------------------------------------------------------------------------------------

@mixin mobile {
  @media (min-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

// single page
@mixin big-mobile {
  @media (max-width: $big-mobile) {
    @content;
  }
}

@mixin big-tablet {
  @media (max-width: $big-tablet) {
    @content;
  }
}

$max-width: $content-width;

// Use for horizontal container limit
@mixin contentWidth ($method) {
  #{$method}-left: 5vw;
  #{$method}-right: 5vw;

  @include big-tablet {
    #{$method}-left: 5vw;
    #{$method}-right: 5vw;
  }

  @include big-mobile {
    #{$method}-left: $space-md;
    #{$method}-right: $space-md;
  }

  @media (min-width: $x-big-desktop) {
    #{$method}-left: calc((100% - #{$max-width}) / 2);
    #{$method}-right: calc((100% - #{$max-width}) / 2);
  }

}


//--------------------------------------------------------------------------------------
//  Typography
//--------------------------------------------------------------------------------------

@mixin title-base {
  font-family: $font-primary-medium;
  font-size: $font-size-md;
  letter-spacing: 1px;
}

@mixin section-title() {
  @include title-base;

  @include tablet() {
    font-size: $font-size-md-lg;
  }

  @include desktop() {
    font-size: $font-size-xl;
  }
}

@mixin section-body() {
  font-size: $font-size-sm;
  letter-spacing: 1px;

  @include tablet() {
    @include paddingX($spacing-x * 5);
    font-size: $font-size-default;
    letter-spacing: 1.5px;
  }

  @include desktop() {
    @include paddingX($spacing-x * 10);
  }
}


//--------------------------------------------------------------------------------------
//  Misc
//--------------------------------------------------------------------------------------


// Add underline effect on hover
@mixin underline($color) {
  position: relative;

  &::after {
    content: '';
    display: block;
    background: $color;
    width: 0;
    height: 2px;
    position: absolute;
    margin-top: $space-sm;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    @extend %hover-transition;
  }

  &:hover {
    &::after {
      opacity: 1;
      width: 60px;
    }
  }
}
