
// sizes
$space-sm: 0.5rem;
$space-md: 1rem;
$space-lg: 1.5rem;

// colors
$white: #fff;
$black: #000000;
$color-primary: #00053E;
$color-bg-primary: #FFFBF5;
$color-bg-secondary: #FBF4EC;
$color-secondary: #6D0020;
$color-accent: #AE8450;
$color-light-gray: #c8c9d3;
$color-text-muted: #757788;
$color-card-separator: #e3e3e3;
$color-facebook-blue: #3b5998;

$color-tab-borders: $color-light-gray;

$card-box-shadow: 0 3px 6px #00000029;


// FONT VARIABLES
$font-primary: "Open Sans", sans-serif;
$font-primary-medium: "ITC Blair W01 Medium", sans-serif;
$font-primary-light: "ITC Blair W01 Light", sans-serif;
$font-weight-bold: 700;  // only use this for Open sans


// font sizes
$font-size-xxs: .625rem;        // 12px
$font-size-xs: .75rem;        // 12px
$font-size-sm: .875rem;       // 14px
$font-size-default: 1rem;     // 16px
$font-size-md: 1.125rem;      // 18px
$font-size-md-lg: 1.5rem;   // 24px
$font-size-lg: 1.75rem;       // 28px
$font-size-xl: 2rem;          // 32px

$font-size-base: $font-size-sm;   // since we're doing mobile first
$font-size-base-tablet: $font-size-default;
$font-size-base-desktop: $font-size-md;

// GUTTERS
$default-gutter: .875rem;           // 14px

$content-gutter: $default-gutter;
$spacing-x: $default-gutter;        // 14px
$spacing-y: $default-gutter * 1.5;  // 21px


// media query width
$mobile-sm: 320px;
$mobile: 500px;
$tablet: 768px;
$desktop: 1300px;

// single page
$big-mobile: 540px;
$big-tablet: 960px;
$big-desktop: 1240px;
$x-big-desktop: 1650px;
$color-accent-gray: #EFF1F4;
$content-width: $big-desktop;
$base-font-size-value: 16;
$base-font-size: $base-font-size-value + px;
