//pages/_404.scss

.main-404 {
  padding-top: 12rem;
  //background: $black;
  text-align: center;

  h1 {
    font-size: 4rem;
  }

  .btn {
    margin-bottom: 15rem;
  }
}
