.lakes-list {
  @include container;

  ._cities {
    margin-top: $spacing-y * 2;

    & > li {
      margin-bottom: $spacing-y;
      h3 {
        @include section-title;
        color: $color-accent;
        font-weight: 400;
        margin-bottom: $spacing-y;
      }
    }
  }

  ._lakes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &::after {
      content: "";
      width: 100%;

      @include tablet { width: 48%; }
      @media (min-width: $big-tablet) { width: 32%; }
    }

    li {
      width: 100%;
      margin-bottom: $spacing-y;

      @include tablet {
        width: 48%;
      }

      @include desktop {
        width: 31%;
      }
    }

    figure {
      background-size: cover;
      background-position: center center;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      height: 130px;

      &::after {
        background-color: rgba(0,0,0,0.4);
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        z-index: 1;
      }

      &:hover::after {
        background-color: rgba(0,0,0,0.35);
      }
    }

    figcaption {
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      color: $white;
      position: relative;
      z-index: 2;
      text-align: center;

      span {
        font-size: 1rem;
      }
    }
  }
}

.lake-single-intro {
  @include container;
  margin-bottom: $spacing-y;

  @include tablet {
    margin-top: $spacing-y * 3;
  }

  h1 {
    font-size: 1rem;
    @include tablet { font-size: 2rem; }
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-y / 2;
  }

  p {
      font-size: 1rem;
      @include tablet { font-size: 1.5rem; }
      color: $color-accent;
      margin-bottom: $spacing-y;
  }
}

.lake-info {
  margin-top: $spacing-y * 3;
  align-items: flex-start;

  ._content h2 {
    font-size: 1.25rem;
    color: $color-accent;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  div {
    display: block;
  }

  & > div {
    flex-basis: 100%;

    @include tablet {
      flex-basis: 48%;
    }
  }

  ._img {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: $spacing-y * 2;
    width: 100%;

    figure {
      width: 50%;
      text-align: center;
      margin-bottom: $spacing-y;
      display: inline-block;

      img {
        display: inline-block;
        height: 50px;
        width: auto;
        object-fit: contain;
        margin-bottom: 0.4rem;
      }

      h3 {
        font-size: 1.1rem;
        font-weight: $font-weight-bold;
        margin-bottom: 0.25rem;
      }

      p { font-size: 0.9rem; }
    }
  }
}

.share-and-copy {
  @include container;
  margin-top: $spacing-y;
  margin-bottom: $spacing-y * 3;

  .share {
    justify-content: flex-start;
  }

  #permalink-info {
    @include marginX(0);
  }
}

.featured-lakes {
  @include container;
  @include marginY($spacing-y * 2);
  text-align: center;

  h2 {
    @include section-title;
    color: $color-accent;
    margin-bottom: $spacing-y * 2;
  }

  & > p {
    margin-bottom: $spacing-y * 2;
  }

  ul {
    text-align: left;
    margin-bottom: $spacing-y * 2;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 100%;
      margin-bottom: $spacing-y;

      @include tablet {
        width: 48%;
      }

      @media (min-width: $big-tablet) {
        width: 31%;
      }
    }

    figure {
      background-size: cover;
      background-position: center center;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      height: 150px;

      @include tablet() {
        height: 200px;
      }

      @media (min-width: $big-tablet) {
        height: 250px;
      }

      &::after {
        background-color: rgba(0,0,0,0.4);
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        z-index: 1;
      }

      &:hover::after {
        background-color: rgba(0,0,0,0.35);
      }
    }

    figcaption {
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
      color: $white;
      position: relative;
      z-index: 2;
      text-align: center;

      span {
        font-size: 1rem;
      }
    }
  }

  @include tablet() {
    margin-top: $spacing-y * 3;
    margin-bottom: $spacing-y * 3;
  }
}

#photo-gallery.-lakes {
  .has-video img {
    top: auto;
    bottom: 0;
  }

  .video-overlay {
    background: rgba(0,0,0,0.15);
  }

  .bg-as-img {
    background-position: bottom center;
  }
}
