$listing-card-width: 290px;

#view {
    background: $color-bg-secondary;
    margin-bottom: $spacing-y * 2;
}

 #view-content {
    background: $color-bg-primary;
}

#view-content > *:not([data-tabopen]) {
    display: none;
}

%listings-link {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $color-primary;

    &:hover {
        color: $color-accent;
    }
}

#view > #view-toggle {
    padding-top: $spacing-y;
    @include container;
    max-width: 1000px !important;
    display: flex;
    justify-content: flex-end;

    a {
        @extend %listings-link;
        padding: $spacing-y * 0.5 $spacing-x * 2.5;
        border-right: 2px solid transparent;

        &[data-tabopen] {
            background: $color-bg-primary;
            border-right: 2px solid rgba(0, 0, 0, 0.1);
        }
    }
}

#g-map {
    min-height: 600px;
}

#listing-filter {
    @include container;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: $spacing-y 0;
    max-width: 1000px !important;

    h1 {
        font-size: $font-size-lg;
        color: $color-primary;
        font-weight: $font-weight-bold;

        @include tablet() {
            font-size: 2.25rem;
        }
    }

    .selectal-btn {
        background: $color-accent;
        color: #fff;
        border-radius: 0;
        border: none;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        padding: $spacing-y * 0.25 $spacing-x;
        width: 195px;

        & > p { padding: 0; }
        & > span { margin-left: $spacing-x; }
        &:hover { background: lighten($color-accent, 10); }
        &:hover, &:hover > p, &:hover > span {
            color: #fff;
        }
    }

    .selectal-dropdown {
        border: none;
        border-top: 1px solid darken($color-accent, 10);
        z-index: 2;
    }
    .selectal-dropdown-option {
        background: $color-accent;
        color: #fff;
        border-radius: 0;
        border: none;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        padding: $spacing-y * 0.25 $spacing-x;
        width: 160px;

        &:hover { background: lighten($color-accent, 10); }

        &:first-of-type { display: none; }
    }
}

ol#listings {
    @include container;
    max-width: 1280px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    list-style: none;

    max-width: 1000px !important;

    // Case for justify content space between but not for last row, use of filler block
    &::after {
        content: '';
        flex: auto;
        width: $listing-card-width;
        max-width: $listing-card-width;
    }
}

ol#listings > li, .listing-iw {
    .rollover {
        display: block;
        width: 100%;
    }

    .rollover img {
        $img-height: $listing-card-width / 1.3125;
        $num-slides: 4;

        object-fit: cover;
        object-position: center 0px;
        height: $img-height;
        width: 100%;

        @for $i from 0 to $num-slides {
            &[data-rolloveridx="#{$i}"] {
                object-position: center -#{$i * $img-height};
            }
        }
    }

    .info, .actions {
        padding: $spacing-y / 2 $spacing-x;
    }

    .info {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .address {
        font-size: $font-size-md;
        color: $color-accent;
    }

    .address .water-and-street {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .water-and-street {
      display: block;
      color: $color-accent;

      &:hover { color: $color-primary; }
    }

    .address .city {
        color: $color-primary;
        font-size: $font-size-xs;
        display: block;
    }

    .price {
        display: block;
        margin-top: $spacing-y;
    }

    .actions a {
        .label {
            @extend %listings-link;
        }

        .fa-icon {
            margin-left: $spacing-x * 3;
            color: $color-accent;
            font-size: $font-size-xs;
        }
    }

    .unavailable {
      color: #666666;
      font-size: .9rem;
      font-weight: bold;
    }
}

ol#listings > li {
    width: $listing-card-width;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
    background: $color-bg-primary;
    margin-bottom: $spacing-y;
}

@media screen and (max-device-width: 500px) {
    #listing-filter {
        padding-left: $spacing-x;
        padding-right: $spacing-x;
    }
    ol#listings {
        justify-content: center;
        & > li { margin-bottom: $spacing-y * 2; }
    }
}

// Map InfoWindow
.listing-iw {
    background: #fff;
    width: 320px;

    .rollover img { height: 220px; width: 320px; }

    .info, .actions { padding-left: 0; padding-right: 0; }
    .actions { padding-bottom: 0; }
}

.gm-style-iw-d > div {
    overflow: hidden;
}



.header-price {
    display: block;
    margin-top: $spacing-y;
    font-size: 2rem ;

    @media (max-width: $big-mobile) {
        font-size: 1rem;
    }
}

.price--sold {
    display: block;
    margin-top: $spacing-y;
}


.badge {
  position: relative;
  display: block;
}

.badge__exclusive {
  position: absolute;
  right: 0;
  bottom: $spacing-y;
  background: $color-accent;
  padding: $spacing-y / 4 $spacing-y / 2;
  color: $white;
}


.has-video {
  position: relative;
  display: block;
  //pointer-events: none;
  cursor: pointer;
  overflow: hidden;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    background: rgba(0, 0, 0, .4);
    cursor: pointer;
  }

  .fa-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 50px;
    // border: 2px solid $white;
    // border-radius: 50%;
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, .8);
  z-index: 1000;

  .btn-close {
    position: fixed;
    top: $spacing-y;
    right: $spacing-x;
    z-index: 1001;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }


  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1002;
  }

  .iframe-wrapper {
    width: 80%;
    max-width: 1024px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 56.25%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

//-------------------------------------------------------------------------
// Featured listings module to be used on other pages
//-------------------------------------------------------------------------

.featured-listings {
  @include container;
  @include marginY($spacing-y * 2);
  text-align: center;

  .btn {
    margin-top: $spacing-y * .6 ;
  }

  h2 {
    @include section-title;
    color: $color-primary;
    margin-bottom: $spacing-y * 2;
  }

  ol#listings {
    text-align: left;
    margin-bottom: $spacing-y * 2;
  }

  @include tablet() {
    margin-top: $spacing-y * 4;
    margin-bottom: $spacing-y * 3;

    .btn {
      margin-top: 0 ;
    }

    .listing-cards {
      @include paddingX($spacing-x * 10);
      margin-top: $spacing-y * 2.5;
      margin-bottom: $spacing-y * 5;
    }
  }
}

#associated-lake {
  background-size: cover;
  background-position: center center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $spacing-y $spacing-x;
  position: relative;

  @include mobile() {
    height: 300px;
  }

  @include tablet() {
    height: 500px;
  }

  &::after {
    background-color: rgba(0,0,0,0.4);
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  * { position: relative; z-index: 2; }

  h2 {
    color: $white;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: $font-primary-medium;
    margin-bottom: $spacing-y;
    text-align: center;

    @include mobile() {
      font-size: 1.25rem;
    }

    @include tablet() {
      font-size: 1.5rem;
    }
  }
}

.looking-to-sell {
  background: $color-primary;
  color: $white;

  &-content {
    @include container;
    @include paddingY($spacing-y * 1.2, $spacing-y * 3);
    text-align: center;

    h2 {
      @include section-title;
      margin-bottom: 2rem;
    }

    p {
      @include section-body;
      margin-bottom: 2rem;
      line-height: 1.5;
    }
  }

  @include tablet() {
    &-content {
      @include paddingY($spacing-y * 4, $spacing-y * 6);
    }
  }
}
