// assets\stylesheets\components\_cards.scss

// https://tobiasahlin.com/blog/common-flexbox-patterns/

//--------------------------------------------------------------------------------------
//  Listing cards (container)
//--------------------------------------------------------------------------------------

.listing-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-content: space-around;

  @include tablet() {
    flex-direction: row;
  }
}


//--------------------------------------------------------------------------------------
//  Listing card (single)
//--------------------------------------------------------------------------------------

.listing-card {
  flex: 0 30%;
  text-align: left;
  box-shadow: $card-box-shadow;
  margin-bottom: $spacing-y;

  figure {
    margin-bottom: $spacing-y;
  }

  p, a {
    padding-left: .5rem;
  }

  p:first-of-type {
    color: $color-accent;
  }

  p:last-of-type {
    @include paddingY($spacing-y);
    padding-top: $spacing-y;
    padding-bottom: $spacing-y * .5;
    border-bottom: 1px solid $color-card-separator;
    margin-bottom: 1rem;
  }

  a {
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-y * .8;
    display: inline-block;
    width: 100%;
    color: $color-primary;

    i {
      color: $color-accent;
      padding-left: $spacing-x * 4;
    }
  }

  @include tablet() {
    margin-bottom: 5%;  // (100% - 30*3)/2
  }
}
