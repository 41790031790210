@charset "UTF-8";
// styling structure
// https://sass-guidelin.es/#the-7-1-pattern
// https://github.com/HugoGiraudel/sass-boilerplate




// 1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';
@import 'abstracts/placeholder';


// 2. Vendors
@import 'vendors/reset';
@import 'vendors/normalize';

// 3. Base stuff
@import 'base/base';
@import 'base/typography';

// 4. Layout-related sections
@import 'layout/nav';
@import 'layout/footer';


// 5. Components
@import 'components/buttons';
@import "components/forms";
@import "components/misc";
@import "components/boxes";
@import "components/cards";
@import "components/gallery";
@import "components/shared";
@import "components/media-object";
@import "components/video-hero";
@import "components/map-properties-sold";


// 6. Page-specific styles
@import 'pages/home';
@import 'pages/contact';
@import 'pages/sell-with-us';
@import 'pages/about';
@import 'pages/404';
@import 'pages/form-sent';
@import 'pages/listings';
@import "pages/single-listing";
@import "pages/lakes";
