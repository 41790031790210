// assets\stylesheets\abstracts\_placeholder.scss
// Krozz stuff

%hover-transition {
  // Add !important in case end user using Grammerly in browser that inject sytle to DOM
  transition: .3s ease-in-out !important;
}

//%heading-decorative {
//  &::after {
//    content: '';
//    display: block;
//    width: 73px;
//    height: 5px;
//    background: $color-accent;
//    margin-top: $space-md;
//  }
//}

//%disable-input-appearance {
//  // Disable default styling on ff
//  -moz-appearance: none;
//  // Disable ugly ass outline on firefox
//  &:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }
//  // Disable default styling on webkit browsers
//  -webkit-appearance: none;
//  // Disable default arrow on IE 11+
//  &::-ms-expand { display: none; }
//}
//
//%cta-arrow {
//  position: relative;
//  &::before {
//    content: '';
//    display: block;
//    width: 12px;
//    height: 2px;
//    background: $white;
//    position: absolute;
//    top: 50%;
//    transform: translateY(-50%);
//    right: $space-md;
//  }
//
//  &::after {
//    content: '';
//    display: block;
//    width: 10px;
//    height: 10px;
//    border-top: 2px solid $white;
//    border-right: 2px solid $white;
//    position: absolute;
//    right: 1rem;
//    top: 50%;
//    transform: translateY(-50%) rotate(45deg);
//  }
//}

//%visual-blob-right {
//  position: relative;
//  &::before {
//    content: '';
//    background-image: url('/assets/ampersand.svg');
//    background-repeat: no-repeat;
//    opacity: .15;
//    display: block;
//    width: 30vw;
//    height: 30vw;
//    background-size: contain;
//    position: absolute;
//    right: 0;
//    bottom: 0;
//    z-index: -1;
//  }
//}
//
