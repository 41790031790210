// assets\stylesheets\components\_buttons.scss


//--------------------------------------------------------------------------------------
//  Buttons
//--------------------------------------------------------------------------------------

.btn {
  $offset: 30px;
  display: inline-block;
  font-size: $font-size-sm;
  text-decoration: none;
  color: $white;
  border: none;
  background: $color-accent;
  transition: background-color 250ms ease-in-out;
  padding: .8em 3em;
  z-index: 2;

  &:visited, &:focus, &:active, &:hover {
    color: $white;
  }

  &:hover {
    background-color: darken($color-accent, 10%);

    &::before {
      width: 0;
      left: 0;
      background-color: $color-primary;
    }
  }

  @include tablet {
    font-size: $font-size-default;
  }

  &.-transparent {
    color: $white;
    background-color: transparent;
    border: 2px solid $color-accent;

    &:hover {
      background-color: $color-accent;
    }
  }
}


//--------------------------------------------------------------------------------------
//  Hamburger
//--------------------------------------------------------------------------------------

.btn-nav {
  //display: none;
  $dim: 46px;

  & > * {
    @extend %hover-transition;
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  width: $dim;
  height: $dim;
  position: absolute;
  z-index: 100;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  & > * {
    width: 40%;
    height: 2px;
    background: $white;
    display: block;
    @include marginX(auto);

    &:nth-child(2) {
      @include marginY(4px);
    }
  }

  &.active {
    & > * {
      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(1) {
        transform: rotate(45deg);
        position: relative;
        top: 6px;
      }

      &:nth-child(3) {
        transform: rotate(135deg);
        top: -6px;
        position: relative;
      }
    }
  }
}
