*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin debug($color: magenta) {
  outline: 3px solid $color;
}

body {
  font-family: $font-primary;
  font-size: $font-size-base;
  color: $color-primary;
  line-height: 1.2;
  background: $color-bg-primary;

  @media screen and (min-width: $tablet) {
    font-size: $font-size-base-tablet;
  }

  // for when mobile menu is open
  &.lock {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: $color-primary;

  &:visited, &:focus, &:active, &:hover {
    color: inherit;
  }
}


// layout
//-----------------------

.container {
  @include container();
}

// components
//-----------------------

.logo {
  width: 271px;
}
