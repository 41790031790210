.form-sent {
  @include container();
  margin: 2rem auto;

  h1 {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin: 0;
  }
  text-align: center;
  p {
    @include paddingY($spacing-y * .5);
  }
}
