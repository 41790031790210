// address, price
.header-listing {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: $spacing-y;

  h1 {
    font-size: $font-size-default;
  }

  .street {
    font-size: $font-size-sm;
    color: $color-accent;
  }

  .city,
  .price {
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  .price {
    margin-bottom: auto;
    padding-top: .8rem;
    font-size: $font-size-default;
  }

  @include tablet() {
    @include marginY($spacing-y * 3, $spacing-y);
    .city, .price {
      font-size: $font-size-xl;
    }
    .street {
      font-size: $font-size-md;
    }
  }

  ._back {
    margin-top: $spacing-y;
    flex-basis: 100%;
    width: 100%;

    a {
      color: #00053E;
    }
  }
}

// featured image(s), slider
// --------------------------------------------------------------------------
.photo-gallery {
  $img-spacing: $spacing-x * .6;

  img {
    vertical-align: middle;
  }
  // large images
  // only one large image in mobile, 2 otherwise
  .gallery-listing {
    li {
      display: block;
      margin-bottom: $spacing-y / 4;
    }

    [data-idx="2"] {
      display: none;
    }

    @include tablet() {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      li:nth-child(2) {
        padding-left: $spacing-x;
      }

      [data-idx="2"] {
        display: block;
      }
    }
  }

  .slider {
    margin-right: $img-spacing * -1; // hack suggested by siema owner
    margin-bottom: $spacing-y;

    li {
      padding-right: $img-spacing;
    }

    a {
      display: inline-block;
    }

    .thumbnails-img {
      max-height: 100px;

      img {
        //object-fit: cover;
        //object-position: center;
      }
    }

    @include tablet() {
      [data-idx="2"] {
        display: none;
      }
    }
  }
}

.slider-wrapper {
  position: relative;

  .slider-next,
  .slider-prev {
    display: none;
    background: $color-primary;
    color: $white;
    border: none;
    @include paddingX(10px);
    @include paddingY(6px);
  }

  .slider-prev, .slider-next {
    position: absolute;
    top: 40px;
  }

  .slider-next {
    right: 0;
  }

  .slider-prev {
    left: 0;
  }

  @include tablet() {
    .slider-next, .slider-prev {
      display: block;
    }
  }
}

// ----------------------------------------------------------------------------
//  bedrooms, bathrooms, garage and social media
// ----------------------------------------------------------------------------
$break-point-spec-n-share: 900px;  // custom breakpoint for this part

// container for both specs and share
// --------------------------------------------------------------------------
.specs-n-share {
  @media (min-width: $break-point-spec-n-share) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $spacing-y * 1.5;
  }
}

// rooms and icons
// --------------------------------------------------------------------------
.specs {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .rooms, .share {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    @include paddingY(1rem);
    @include paddingX(1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      margin-bottom: $spacing-y * .75;
    }
  }

  .count {
    font-weight: $font-weight-bold;
    margin: 0 auto;
  }

  .room-label {
    display: none;
  }

  @media (min-width: $break-point-spec-n-share) {
    li {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      img {
        margin-bottom: 0;
        margin-right: $spacing-x;
      }
    }
    .room-label {
      display: block;
      margin-left: $spacing-x * .5;
    }
  }
}

// socials
// --------------------------------------------------------------------------
.controls {
  text-align: center;

  @media (min-width: $break-point-spec-n-share) {
    text-align: right;
  }
}

.get-more-link {
  margin-bottom: $spacing-y;
  cursor: pointer;
}

.share {
  $icon-margin: .3em;
  color: $white;
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-y / 2;

  .fb {
    background: $color-facebook-blue;
    color: $white;

    a, i {
      padding: .875em 1em;
      color: inherit;
    }

    span {
      margin-right: 1em;
      font-weight: $font-weight-bold;
    }
  }

  .sms, .copy-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    a { line-height: 0; }
  }

  .sms {
    background: #4cd964;
    @include paddingX(1rem);
    margin-left: $icon-margin;

    svg {
      width: 18px;
    }
  }

  .copy-link {
    background: #999999;
    cursor: pointer;
    @include paddingX(1rem);
    margin-left: $icon-margin;
    text-align: center;

    svg {
      width: 18px;
    }
  }
}

// box for copying current url
// --------------------------------------------------------------------------
#permalink-info {
  display: none;

  &.open {
    display: block;
  }

  border: 1px solid $color-primary;
  margin: $spacing-y auto;
  position: relative;
  max-width: 300px;
  background: $white;
  @include paddingY($spacing-y * .875);
  @include paddingX($spacing-x);

  .field--sm {
    @include marginY($spacing-y * .5);
    width: 100%;
    font-size: $font-size-xs;
  }

  p:first-of-type {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    text-transform: capitalize;
  }

  p:last-of-type {
    font-size: $font-size-xs;
    //text-align: right;
  }

  .close {
    cursor: pointer;
    position: absolute;
    text-align: center;
    background: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 50%;
    color: $white;
    width: 24px;
    height: 24px;
    top: 5px;
    right: 5px;

    i {
      margin-left: 1px;
    }
  }
}

// ----------------------------------------------------------------------------
//  tabs
// ----------------------------------------------------------------------------

body.single-listing {
  padding-bottom: 35px;

  @include tablet() {
    padding-bottom: 0;
  }
}

.tab-wrapper {
  @include tablet() {
    @include container();
  }
}

.tab {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex-grow: 1;
    text-align: center;
    border-left: 1px solid $color-tab-borders;

  }

  li:last-child {
    border-right: 1px solid $color-tab-borders;
  }

  li {
    display: block;
    font-size: $font-size-xxs;
    text-decoration: none;
    color: $color-primary;
    background: #f5f5f5;
    transition: background-color 250ms ease-in-out;
    padding: 1.25em 1em;
    text-transform: uppercase;
    cursor: pointer;
    border-top: 1px solid $color-tab-borders;

    &.active {
      color: $white;
      background: $color-primary;
    }

    a {
      color: inherit;
    }

    @include mobile() {
      font-size: $font-size-xs;
    }

    @include desktop() {
      font-size: $font-size-default;
    }
  }
}

// ----------------------------------------------------------------------------
//  Tab content
// ----------------------------------------------------------------------------

.tab-content-wrapper {
  background: $color-primary;
  color: $white;
}

.tab-content {
  margin: 0 auto;
  max-width: 1200px;

  // special case to make this tab fit
  & > li[data-label='Rooms'] {
    @include paddingX($spacing-x);

    @include tablet() {
      @include paddingX($spacing-x * 2)
    }
  }

  p {
    @include marginY($spacing-y);
    line-height: 1.5;
    letter-spacing: 1px;
  }

  & > li {
    display: none;
    @include paddingY($spacing-y);
    @include paddingX($spacing-x * 2);
    width: 100%;

    section {
      width: 100%;
    }

    &.active {
      display: flex;
    }
  }

  h3 {
    font-weight: $font-weight-bold;
  }

  a {
    color: inherit;
  }

  .summary {
    position: relative;
  }

  // container with "show more" button
  // --------------------------------------------------------------------------
  .show-more {
    display: none;
    position: absolute;
    width: 100%;
    background: rgba($color-primary, 0.6);
    bottom: 0;
    text-align: center;
    @include paddingY($spacing-y);

    &.active {
      display: block;
    }

    @include mobile() {
      opacity: 0;
      display: block;
      z-index: -1000;
    }
  }

  .addendum {
    height: 2rem;
    overflow: hidden;

    &.active {
      height: 100%;
      overflow: auto;
    }

    @include mobile() {
      height: 100%;
      //overflow: auto;
    }
  }
}

// rooms
// --------------------------------------------------------------------------

.table-room {
  width: 100%;
  font-size: $font-size-xs;

  thead tr {
    border-bottom: 1px solid $color-accent;
  }

  th {
    padding: $space-md;
    font-weight: bold;
    text-align: left;
  }

  tr {
    border-bottom: 1px solid darken($color-tab-borders, 50%);
  }

  td {
    padding: $space-md;
  }

  @include tablet() {
    font-size: $font-size-base;
  }
}

// tab "Features"
// --------------------------------------------------------------------------
.features-tab {
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  .hide { // hack for first div
    display: none;
  }

  .features-group {
    flex-basis: 100%;
    margin-bottom: $spacing-y;

    h3 {
      font-size: $font-size-default;
      border-bottom: 1px solid $color-accent;
      padding-bottom: $spacing-y * .25;
      margin-bottom: $spacing-y;
    }
    p {
      font-size: $font-size-sm;
      @include marginY($spacing-y * .5);
    }

    @include mobile() {
      flex-basis: 45%;
    }

    @include tablet() {
      flex-basis: 30%;
    }

    @include desktop() {
      flex-basis: 23%;
    }
  }

}


// assessment-expenses
// ----------------------------------------------------------------------------
.table-sum {
  width: 100%;

  @include big-mobile {
    max-width: initial;
    width: 100%;
  }

  td {
    padding-bottom: $space-md;

    &:first-child {
      font-weight: bold;
      @include big-mobile {
        font-weight: normal;
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  tfoot {
    border-top: 1px solid $color-accent;

    td {
      @include paddingY($space-md);
    }
  }
}

.tab-content .list-item {
  @include big-mobile {
    margin-bottom: $space-md;
  }
}

.tab-content .col > li {
  @include big-mobile {
    margin-bottom: 0;
  }
}


.inclusions-exclusions,
.assessment-expenses {
  display: block;

  h3 {
    @include marginY($spacing-y);
    padding-bottom: .3rem;
    border-bottom: 1px solid $color-accent;
  }

  @include mobile() {
    display: flex;
    justify-content: space-between;
    & > * {
      flex-basis: 40%;
    }
  }
}

.inclusions-exclusions .list-item {
  margin-bottom: $spacing-y * .5;
  letter-spacing: 1px;
}
