// layout/footer.scss

//--------------------------------------------------------------------------------------
//  Common stuff used in footer
//--------------------------------------------------------------------------------------

$footer-section-margin: $spacing-y * 1.5;
$footer-links-margin-sm: $spacing-y;
$footer-links-margin-md: $spacing-y;

@mixin section-separator {
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: $color-accent;
  }

  @include tablet() {
    &:before {
      display: none;
    }
  }
}

@mixin footer-section-title {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-y;

  @include mobile() {
    letter-spacing: 1.5px;

  }
}

@mixin footer-links-margin($size: $footer-links-margin-sm) {
  li {
    margin-bottom: $size;
  }
}


//--------------------------------------------------------------------------------------
//  Footer styling
//--------------------------------------------------------------------------------------

footer {
  background: $color-primary;
  text-align: center;
  color: $white;
}

.footer { // no .footer class in the HTML, only used as prefix
  &-content {
    @include container;
    margin-top: $spacing-y * 2;
    width: 70%;
    padding-bottom: $spacing-y;
  }

  &-description {
    color: $white;
    width: 100%;

    p {
      display: none;
      @include marginX(auto);
      letter-spacing: 1px;
    }
  }

  &-sitemap {
    @include section-separator;

    p {
      margin-top: $spacing-y;
      @include footer-section-title;
    }

    @include footer-links-margin();
  }

  &-infos {
    @include footer-links-margin();
    @include section-separator;

    p {
      margin-top: $spacing-y;
      @include footer-section-title;
    }

  }

  &-social-wrapper {
    @include section-separator;
  }

  &-social {
    display: flex;
    justify-content: space-around;
    @include paddingX($spacing-x * 3);
    margin-top: $spacing-y;
  }

  &-made-by {
    background: $color-bg-primary;

    .container {
      padding-top: $spacing-y;
      padding-bottom: $spacing-y * .8;
      text-align: center;
      color: $color-text-muted;
    }

    p {
      line-height: 1.6;
      width: 100%;
    }

    a {
      color: inherit;
    }

    i {
      color: $color-secondary;
    }
  }

  //  media queries
  //--------------------------------------------------------------------------------------

  @include mobile() {
    &-content {
      width: 60%;
    }
  }

  @include tablet() {
    &-content {
      display: flex;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      margin-top: $spacing-y;
      padding-bottom: $spacing-y * 2;
    }

    &-description {
      width: 33%;
      margin: $spacing-y 0 0;


      p {
        margin-top: $spacing-y;
        display: block;
        line-height: 1.5;
        font-size: $font-size-sm;
      }
    }

    &-sitemap {
      margin-left: auto;
      @include footer-links-margin($footer-links-margin-md)
    }

    &-infos {
      margin-left: auto;
      @include footer-links-margin($footer-links-margin-md)
    }

    &-social {
      justify-content: flex-start;
      @include paddingX(0);

      :not(:last-child) {
        margin-right: $spacing-x * 2;
      }
    }

    &-made-by {
      .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        p {
          margin-top: auto;
          width: auto;
        }
      }
    }
  }
}
