// assets\stylesheets\components\_misc.scss

//--------------------------------------------------------------------------------------
//  Separator between sections
//--------------------------------------------------------------------------------------

.section-separator {
  height: 22px;
  background: $color-secondary;
  border-bottom: 2px solid $color-accent;

  @include tablet {
    height: 40px;
  }
}

//--------------------------------------------------------------------------------------
//  Section simple hero
//--------------------------------------------------------------------------------------
// for pages Why us, about, contact

.simple-hero {
  @include container;
  @include marginY($spacing-y, $spacing-y);

  h1 {
    font-size: $font-size-lg;
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  @include tablet() {
    font-size: 2.3rem;
    @include marginY($spacing-y * 2.5, $spacing-y);

    h1 {
      font-size: 2.25rem;
    }
  }
}

.field {
  &--sm {
    padding: .5em .5em;
  }
}

#call-us {
  position: fixed;
  right: 90px;
  bottom: 28px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $color-accent;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 9999;

  @media (min-width: 998px) {
    display: none;
  }
}
